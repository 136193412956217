@charset "UTF-8";

//setting
$main-color: #000000;
//$key-color1: #458c00;
//$key-color2: #a3cf76;
//$back-color1: #f6f5f3;
//$back-color2: #e4f0da;

//@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@400;600&display=swap');

$gothic: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', "メイリオ", Meiryo,   sans-serif;	
//$font-family-min: "Noto Serif JP", "Hiragino Mincho ProN", "游明朝", "YuMincho", "HGS明朝E", serif;

$font-eng: 'Julius Sans One', sans-serif;

//max-width
$max-width-l:1000px;

// media query
$tab: 960px; // タブレット
$sp: 480px; // スマホ

@mixin media-tab {
  @media only screen and (max-width: ($tab)) {
    @content;
  }
}
// 呼び出すときは　@include media-tab { }
@mixin media-sp {
  @media only screen and (max-width: ($sp)) {
    @content;
  }
}
// 呼び出すときは　@include media-sp { }

//clearfix 呼び出すときは　@include clearfix;
@mixin clearfix {
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}
