@charset "UTF-8";

@import "_setting.scss";

/// toppage

/***************** mainimage-pc ***************/

#mainimage .image2021 {
  width: 100%;
  img{
		width:100%;
		}
}

///ここから不要削除
#mainimage .image {
  width: 100%;
  height: 0;
  padding-top: 66.66%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../images/mainimage2001-pc.jpg);
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
  #mainimage .image {
    background-image: url(../images/mainimage2001-pc@2x.jpg);
  }
}
/***
#mainimage #pc_slide {
   position:relative;
   width:100%;
   height:440px;
}
#mainimage #pc_slide li {
   position:relative;
   height:440px;
   width:100%;
   overflow:hidden;
   margin:0;
   padding:0;
   background-position:center top;
   background-repeat:no-repeat;
   background-size:cover;
   background-image: url(../images/mainimage1-pc.jpg)
}
#sp_slide {
	display:none;
}
**/
@media only screen and (max-width: 767px) {
  #mainimage .image {
    background-image: url(../images/mainimage2001-pc.jpg);
  }
}
@media only screen and (max-width: 480px) {
  #mainimage .image {
    padding-top: 66.66%;
    background-image: url(../images/mainimage2001-sp@2x.jpg);
  }
}
/**
	#pc_slide {
		display:none;
	}
	#sp_slide {
	display:block;
}**/

//ここまで不要削除

/***************** COMMON ***************/
h2 {
  font-family: $font-eng;
  font-weight: normal;
  letter-spacing: 0.1em;
}

/********************* HEADER *********************/

/*** Black ***/
header #head_bar {
  background: #000;
  /* #833c0c;*/
}
/***************** LEAD ***************/
#top #lead {
  margin: 60px auto;
  max-width: 1000px;
}
#top #lead .text {
  font-size: 90%;
  line-height: 200%;
}
@include media-tab {
  #top #lead {
    margin: 40px auto;
    width: 84%;
  }
  #top #lead .text {
    text-align: left;
    font-size: 80%;
  }
}
/***************** Information ***************/
#top #information {
  margin: 60px auto 80px;
  max-width: 1100px;
}
#top #information:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
#top #information h2 {
  font-size: 150%;
  margin-bottom: 40px;
}
#top #information h2 a {
  text-decoration: none;
  color: #000;
}
@include media-tab {
  #top #information {
    margin: 30px auto;
    max-width: 84%;
    width: 84%;
  }
  #top #information h2 {
    margin-bottom: 20px;
  }
}

/***************** NEWS ***************/
#top #news {
  float: left;
  width: 45%;
}

#top #news dl {
  box-sizing: border-box;
  text-align: left;
  margin: 0 auto;
  padding: 2%;
  border-bottom: 1px solid #ccc;
  line-height: 180%;
}
#top #news dl:first-child {
  border-top: 1px solid #ccc;
}
#top #news dl:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
#top #news dt {
  font-size: 75%;
}
#top #news dd {
  font-size: 90%;
}
#top #news dd span {
  background-color: #6464b3;
  color: #fff;
  padding: 0 5px;
}
#top #news dd span a {
  color: #fff;
}

#top #news img {
  vertical-align: middle;
  margin: 0 0 0 8px;
}
#top #news dd img {
  vertical-align: middle;
  display: block;
  float: left;
  margin: 5px 15px 0 0;
}
@include media-tab {
  #top #news {
    float: none;
    width: 100%;
  }

  #top #news dl {
    line-height: 1.7;
  }
  #top #news dt {
  }
  #top #news dd {
    font-size: 80%;
  }
  #top #news dd img {
    float: none;
    margin: 5px auto;
  }
}
/***************** BLOG ***************/
#top #blog {
  float: right;
  width: 45%;
}

#top #blog .bloglist {
  margin: 0 auto;
  border-top: 1px solid #ccc;
}
#top #blog .bloglist article {
  margin: 0 0 0px;
  text-align: left;
  border-bottom: 1px solid #ccc;
  padding: 2% 20px 2% 2%;
  transition: 0.5s;
  position: relative;
}
#top #blog .bloglist article a {
  display: block;
  color: #000;
}
#top #blog .bloglist article:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
#top #blog .bloglist article:hover {
  background: #f2f2f2;
}
#top #blog .bloglist article:hover:before {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%); /* Safari用 */
  transform: translateY(-50%);
  right: 3px;
  color: #ccc;
  font-size: 40px;
  font-family: "FontAwesome";
  content: "\f105";
}
#top #blog .bloglist article .image {
  float: left;
  width: 30%;
}
#top #blog .bloglist article .text {
  float: right;
  width: 65%;
}
#top #blog .bloglist article .text .day {
  font-size: 75%;
  margin: 5px 0 0;
}
#top #blog .bloglist article .text h3 {
  font-size: 90%;
  font-weight: normal;
  line-height: 1.8;
}
#top #blog .bloglist article .text ul.cat {
  letter-spacing: -1em;
}
#top #blog .bloglist article .text ul.cat li {
  letter-spacing: 0em;
  display: inline-block;
  margin: 0 2px 0 0;
  border: 1px solid #000;
  font-size: 75%;
  line-height: 1;
  padding: 2px 5px;
}
@include media-tab {
  #top #blog {
    float: none;
    width: 100%;
    margin: 40px auto 0;
  }

  #top #blog .bloglist {
    max-width: 100%;
  }
  #top #blog .bloglist article {
    padding: 3% 0;
  }
  #top #blog .bloglist article:hover {
    background: #fff;
  }
  #top #blog .bloglist article:hover:before {
    content: "";
  }
  #top #blog .bloglist article .image {
    float: left;
    width: 35%;
  }
  #top #blog .bloglist article .text {
    float: right;
    width: 60%;
  }
  #top #blog .bloglist article .text .day {
    font-size: 70%;
    margin: 0px 0;
  }
  #top #blog .bloglist article .text h3 {
    font-size: 80%;
    line-height: 1.7;
  }
  #top #blog .bloglist article .text ul.cat li {
    font-size: 70%;
  }
}
/***************** BESPOKE & PROPOSAL ***************/
#top_main1 {
  margin-bottom: 60px;
}
#top_main1 section {
  float: left;
  width: 50%;
}
#top_main1 section h2 a {
  background: #000;
  color: #fff;
  text-decoration: none;
  display: block;
  font-family: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "メイリオ", Meiryo, sans-serif;
  text-align: center;
  font-size: 90%;
  padding: 10px 0;
}
#top_main1 section#bespoke h2 a {
  border-right: 1px solid #fff;
}
#top_main1 section a:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
  -ms-filter: "alpha( opacity=80 )";
}
#top_main1 section .image img {
  width: 100%;
}
@include media-tab {
  #top_main1 {
    margin-bottom: 60px;
  }
  #top_main1 section {
    float: none;
    width: 100%;
  }
  #top_main1 section h2 a {
    font-size: 70%;
  }
  #top_main1 section#bespoke h2 a {
    border-right: none;
  }
}

/***************** company_photo2 ******************/
.photo {
  width: 1000px;
  margin: 0 auto;
  position: relative;
}

#top_main2 {
  padding-bottom: 50px;
  margin: 0 auto;
}
@include media-tab {
  #top_main2 {
    padding-bottom: 0px;
  }
}
.photo .image {
  width: 100%;
  height: 0;
  padding-top: 97.8%;
  background-image: url("../company/images/photo2.jpg");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
		@media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx){
background-image: url("../company/images/photo2@2x.jpg");
}
}
@include media-sp {
  .photo .image {
    background-image: url("../company/images/photo2-sp.jpg");
  }
}
.photo ul {
  position: absolute;
  z-index: 200;
  top: 70px;
  left: 0;
}
.photo li {
  position: absolute;
  width: 33px;
  height: 33px;
  display: block;
  cursor: pointer;
  z-index: 10;
}

.photo li:hover {
  width: 36px;
  height: 36px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.photo li ul {
  display: block !important;
  opacity: 1;
  background: #fff;
  padding: 20px;
  position: absolute;
  z-index: 20 !important;
  width: 300px;
  top: 40px;
  left: -170px;
  background-color: rgba(255, 255, 255, 0.9);
  filter: progid:DXImageTransform.Microsoft.Gradient(GradientType=0,StartColorStr=#99ffffff,EndColorStr=#99ffffff); /*IE8以下用*/
  /* 横方向　下方向　ぼかし　色＆透過 */
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 1;
}
.fade {
  display: none !important;
  opacity: 0 !important;
}

.photo li ul li {
  text-align: left;
  position: relative;
  width: 100%;
  height: auto;
  display: block;
}
.photo li ul li:hover {
  width: auto;
  height: auto;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.photo li ul li .image {
  margin-bottom: 5px;
}
.photo li ul li h3 {
  font-size: 90%;
}
.photo li ul li p {
  font-size: 80%;
  line-height: 160%;
}
.photo li ul li .no {
  display: none;
}

.photo ul li .batsu {
  position: relative;
  z-index: 2;
}
@include media-tab {
  .photo {
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  .photo ul {
    position: relative;
    z-index: auto;
    top: auto;
    left: auto;
    margin: 8%;
  }
  .photo li {
    position: relative;
    z-index: auto;
    top: auto;
    left: auto;
    width: 100%;
    height: auto;
    display: block;
    cursor: pointer;
  }
  .photo li .batsu {
    display: none;
  }
  .photo li:hover {
    width: auto;
    height: auto;
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
  }
  .photo li ul {
    display: block !important;
    opacity: 1;
    margin: 20px 0;
    border-bottom: 1px solid #ccc;
    padding: 0px 0 20px 0;
    position: relative;
    width: 100%;
    top: auto;
    left: auto;
    background-color: none;
    box-shadow: none;
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
  }
  .fade {
    display: block;
    opacity: 1 !important;
  }
  .photo li ul li {
    text-align: left;
    position: relative;
    width: auto;
    height: auto;
    display: block;
  }
  .photo li ul li:hover {
    width: auto;
    height: auto;
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
  }
  .photo li ul li .image {
    margin-bottom: 10px;
  }
  .photo li ul li .no {
    display: inline-block;
    background: #000;
    color: #fff;
    padding: 1px;
    text-align: center;
    width: 22px;
    margin-right: 5px;
    margin-bottom: 5px;
    /*全体角丸*/
    border-radius: 3px; /* CSS3草案 */
    -webkit-border-radius: 3px; /* Safari,Google Chrome用 */
    -moz-border-radius: 3px; /* Firefox用 */
  }
}

#photo2 #point21 {
  position: absolute;
  top: 110px;
  left: 250px;
}
#photo2 #point22 {
  position: absolute;
  top: 630px;
  left: 250px;
}
#photo2 #point23 {
  position: absolute;
  top: 150px;
  left: 737px;
}
#photo2 #point24 {
  position: absolute;
  top: 650px;
  left: 737px;
}

@include media-tab {
  #photo2 #point21 {
    position: relative;
    top: auto;
    left: auto;
  }
  #photo2 #point22 {
    position: relative;
    top: auto;
    left: auto;
  }
  #photo2 #point23 {
    position: relative;
    top: auto;
    left: auto;
  }
  #photo2 #point24 {
    position: relative;
    top: auto;
    left: auto;
  }
  #photo2 #point25 {
    position: relative;
    top: auto;
    left: auto;
  }
}


/***************** JEANS SOMMELIER ******************/
#sommelier li{
	width:450px;
	display:inline-block;
	margin:0 15px 60px;	
}

@include media-tab {
#sommelier{
	margin: 0 8% !important;
}
#sommelier li {
	width:80%;
	display:block;
	margin:2em auto;
	min-width:100px;
}
}

/***************** japandenim ******************/
#japandenim{
margin:50px 0 0;
}
#japandenim p{
	font-size:95%;
}
#japandenim li{
	width:350px;
	display:inline-block;
	margin:10px 15px 60px;	
}

@include media-tab {
#japandenim{
	margin: 50px 8% 0 !important;
}
#japandenim p{
	text-align: left;
	font-size: 85%;
}
#japandenim li {
	width:80%;
	display:block;
	margin:0 auto 20px;
	min-width:100px;
}
}


/************************************

#sns

************************************/
#sns{
margin: 0 auto 60px auto;
max-width: $max-width-l;
h2 {
  font-family: $font-eng;
  padding: 10px 0;
  font-size: 150%;
  letter-spacing: 0.1em;
  font-weight: normal;
  margin: 0 0 30px 0;
		
}
ul{
display: flex;
-webkit-flex-wrap: wrap; 
flex-wrap:  wrap;
justify-content: center;
}
li{
margin:0 20px;

a{
color:#000;
text-decoration: none;
font-size: 20px;
font-family: $font-eng;
@include media-tab { 
		font-size: 24px;
		}
}
}
}///#sns


///ここから不要削除

/***************** SNS ***************/
#top_sns {
  margin: 0 auto 60px auto;
  width: 1000px;
}
#top_sns section {
  float: left;
  width: 460px;
  margin: 0 20px;
}
#top_sns section h2 {
  margin-bottom: 30px;
  font-size: 140%;
  max-height: 25px;
}
#top_sns section h2 a {
  color: #000;
  text-decoration: none;
}

#top_sns section h2 img {
  height: 35px;
}

/*
#top_sns section#insta h2 a{
	padding-left:32px;
	background:url(../images/icon_insta@2x.png) no-repeat left top;
	background-size:contain;
}
*/
#top_sns section#fb iframe {
  width: 100%;
  height: 450px;
}
#top_sns section#insta iframe {
  width: 100%;
  height: 390px;
}
#top_sns section#insta .insta__wrap{
display: block; 
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
-webkit-flex-wrap: wrap;
flex-wrap:  wrap;

margin: auto;
}
#top_sns section#insta .insta__wrap	li{
	width:48%;
	margin:1%;
	position: relative !important;
}
#top_sns section#insta .insta__wrap li img{
			/*position: absolute;*/
			width:100%;
			height:100%;
			top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  object-fit: cover;
			}
#top_sns section#twitter {
  clear: both;
  float: none;
  width: 100%;
  margin: 0 auto;
  padding: 25px 0 0;
}

.fb-page span,
.fb-page span iframe {
  width: 100% !important;
}
@media only screen and (max-width: 767px) {
  #top_sns {
    margin: 0 auto 30px auto;
    width: 90%;
  }
  #top_sns section {
    float: none;
    width: 100%;
    margin: 0 0 40px 0;
  }

  #top_sns #fb {
    width: 100% !important;
  }
  #top_sns iframe {
    width: 100% !important;
    margin: auto;
  }

  #top_sns section#twitter h2 {
    margin-bottom: 0px;
    font-size: 140%;
  }

  #top_sns section#twitter {
    clear: both;
    float: none;
    width: 100%;
    margin: 0 auto;
    padding: 0 0 0;
  }
}

////ここまで削除
/***************** businessday ******************/

#businessday {
  width: 1000px;
  margin: 0 auto 0;
}

#businessday h2 {
  font-family: $font-eng;
  padding: 10px 0;
  font-size: 150%;
  letter-spacing: 0.1em;
  font-weight: normal;
  margin: 0 0 30px 0;
}

#businessday .g_calendar {
  display: block;
  margin: 0 auto;
}

#businessday .calender {
  float: left;
  width: 320px;
  padding: 0 20px 0 0;
}
#businessday .calender02 {
  float: right;
  width: 320px;
}
#businessday .calender img,
#businessday .calender02 img {
  width: 100%;
}

@include media-tab {
  #businessday {
    width: 100%;
    margin: 0 auto;
  }

  #businessday h2 {
    font-family: $font-eng;
    padding: 10px 0;
    font-size: 150%;
    letter-spacing: 0.1em;
    font-weight: normal;
    margin: 0 0 20px 0;
  }

  #businessday .calender {
    width: 90%;
    float: none;
    margin: 0 auto 15px;
    padding: 0;
  }
  #businessday .calender02 {
    width: 90%;
    float: none;
    margin: 0 auto 0;
    padding: 0;
  }
  #businessday .calender img,
  #businessday .calender02 img {
    max-width: 320px;
  }
  .photo video {
    max-width: 400px;
  }
}
